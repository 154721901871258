import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosError } from "axios";
import clsx from "clsx";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { Password } from "primereact/password";
import { type ReactElement } from "react";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { useResetPassword } from "features/auth";
import toast from "lib/react-hot-toast";

interface ResetPasswordFormProps {
  code: string;
}

const validationSchema = z
  .object({
    newPassword: z
      .string()
      .min(12, "Password must be at least 12 characters long.")
      .regex(/.*[A-Z]+.*/, {
        message: "Password must contain at least one uppercase letter.",
      })
      .regex(/.*\d+.*/, {
        message: "Password must contain at least one number.",
      }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"], // path of error
  });

export type ResetPasswordData = z.infer<typeof validationSchema>;

const defaultValues = {
  newPassword: "",
  confirmPassword: "",
};

function setFormErrors(error: AxiosError, setError: UseFormSetError<ResetPasswordData>) {
  // @ts-expect-error - We have not properly defined the error response data for every case
  const errors = error.response?.data?.errors;
  if (!errors) {
    toast.error("Something went wrong. Please try again shortly or contact QuickBI support.");
  }

  for (const error of errors) {
    const sourcePointer = error.source?.pointer;
    if (sourcePointer?.includes("password")) {
      setError("newPassword", { type: "server", message: error.detail }, { shouldFocus: true });
    } else {
      setError("root", { type: "server", message: error.detail });
    }
  }
}

export function ResetPasswordForm({ code }: ResetPasswordFormProps): ReactElement {
  const navigate = useNavigate();
  const resetPassword = useResetPassword();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ResetPasswordData>({
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  function onSubmit(data: ResetPasswordData): void {
    const attrs = {
      code,
      password: data.newPassword,
    };
    resetPassword.mutate(attrs, {
      onSuccess: () => {
        navigate("/login?msg=password_reset_successful");
      },
      onError: (error) => {
        setFormErrors(error as AxiosError, setError);
      },
    });
  }

  function getFormErrorMessage(name: keyof ResetPasswordData | "root"): ReactElement | undefined {
    if (!errors[name]) {
      return undefined;
    }
    if (name === "root") {
      return <Message className="mb-2" severity="error" content={errors.root?.message} />;
    }
    return <small className="p-error block">{errors[name]?.message}</small>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
      <div className="field mb-4">
        <label htmlFor="newPassword" className={clsx({ "p-error": errors.newPassword })}>
          New password
        </label>
        <Controller
          name="newPassword"
          control={control}
          render={({ field, fieldState }): ReactElement => (
            <Password
              id={field.name}
              autoFocus
              {...field}
              feedback={false}
              toggleMask
              className={clsx("my-2 placeholder-gray-300", { "p-invalid": fieldState.invalid })}
            />
          )}
        />
        {getFormErrorMessage("newPassword")}
        <small>
          Password should be at least 12 characters long, contain both lower and uppercase letters,
          and have at least one number.
        </small>
      </div>
      <div className="field mb-4">
        <label htmlFor="confirmPassword" className={clsx({ "p-error": errors.confirmPassword })}>
          Confirm password
        </label>
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field, fieldState }): ReactElement => (
            <Password
              id={field.name}
              {...field}
              feedback={false}
              toggleMask
              className={clsx("my-2 placeholder-gray-300", { "p-invalid": fieldState.invalid })}
            />
          )}
        />
        {getFormErrorMessage("confirmPassword")}
        <small>Please confirm your password.</small>
      </div>
      {getFormErrorMessage("root")}

      <Button
        type="submit"
        size="small"
        label={resetPassword.isPending ? "Updating..." : "Update password"}
        className="p-button mt-2"
        disabled={resetPassword.isPending}
      />
    </form>
  );
}
