import { type QueryClient } from "@tanstack/react-query";
import { Navigate, type RouteObject } from "react-router-dom";

import { ErrorPage } from "components/error-page";
import { userLoader } from "features/auth";
import { Component as RootLayout } from "layouts/root-layout";

// All the application routes are defined on this file.
// The idea is that it's simple to see all the routes in one place
// and get an idea of where to look for files related to specific routes.

export function getRoutes({ queryClient }: { queryClient: QueryClient }): RouteObject[] {
  const routes = [
    {
      path: "/",
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      loader: userLoader(queryClient),
      children: [
        // Redirect to login page from index route
        {
          index: true,
          element: <Navigate to="/login" />,
        },

        // ------------------------------------
        // Routes without layout
        // ------------------------------------
        // Confirm email outside of layouts so users don't so easily navigate away from it
        {
          path: "confirm-email",
          lazy: () => import("features/auth/routes/confirm-email"),
        },
        // Create workspace outside of layouts so users don't so easily navigate away from it
        {
          path: "create-workspace",
          lazy: () => import("features/organization/routes/create-workspace"),
        },
        // Deme login with magic link
        { path: "demo-login", lazy: () => import("features/auth/routes/demo-login") },

        // ------------------------------------
        // Public routes
        // ------------------------------------
        {
          lazy: () => import("layouts/public-layout"),
          children: [
            {
              errorElement: <ErrorPage />,

              children: [
                // Auth routes
                { path: "login", lazy: () => import("features/auth/routes/login") },
                { path: "oauth-redirects", element: <div /> }, // OAuth redirects just require the url to work
                {
                  path: "/request-password-reset",
                  lazy: () => import("features/auth/routes/request-password-reset"),
                },
                {
                  path: "/reset-password",
                  lazy: () => import("features/auth/routes/reset-password"),
                },
                { path: "signup", lazy: () => import("features/auth/routes/signup") },

                // Redirects from old public pages
                {
                  path: "signup-beta",
                  element: <Navigate to="/signup" replace />,
                },
              ],
            },
          ],
        },

        // ------------------------------------
        // Organization routes
        // ------------------------------------
        {
          path: "/w/:organizationId",
          lazy: () => import("layouts/organization-layout"),
          children: [
            {
              errorElement: <ErrorPage />,

              children: [
                // Redirect to source-connections page from organization index route
                {
                  index: true,
                  element: <Navigate to="source-connections" />,
                },

                // Data routes - only used for Meta app reviews in specific demo organizations
                {
                  path: "data",
                  lazy: () => import("features/data/routes/data"),
                  children: [
                    {
                      path: ":sourceConnectionId/:tableName",
                      lazy: () => import("features/data/routes/table-data"),
                    },
                  ],
                },

                // Data tool routes
                {
                  path: "data-tools",
                  children: [
                    { index: true, lazy: () => import("features/data-tools/routes/data-tools") },
                    {
                      path: ":toolSlug",
                      lazy: () => import("features/data-tools/routes/data-tool"),
                    },
                  ],
                },

                // Report template routes
                {
                  path: "report-templates",
                  children: [
                    {
                      index: true,
                      lazy: () => import("features/report-templates/routes/report-templates"),
                    },
                    {
                      path: ":templateSlug",
                      lazy: () => import("features/report-templates/routes/report-template"),
                    },
                  ],
                },

                // Service accounts routes
                {
                  path: "service-accounts",
                  children: [
                    {
                      index: true,
                      lazy: () => import("features/service-accounts/routes/service-accounts"),
                    },
                    {
                      path: "new",
                      lazy: () => import("features/service-accounts/routes/create-service-account"),
                    },
                  ],
                },

                // Source connection routes
                {
                  path: "source-connections",
                  children: [
                    {
                      index: true,
                      lazy: () => import("features/source-connections/routes/source-connections"),
                    },
                    {
                      path: "new/select",
                      lazy: () => import("features/source-connections/routes/select-source"),
                    },
                    {
                      path: "new/connect",
                      lazy: () => import("features/source-connections/routes/connect-source"),
                    },
                    {
                      path: ":sourceConnectionId",
                      children: [
                        {
                          index: true,
                          lazy: () =>
                            import("features/source-connections/routes/source-connection-details"),
                        },
                        {
                          path: "select-data",
                          lazy: () => import("features/source-connections/routes/select-data"),
                        },
                        {
                          path: "schedule-sync",
                          lazy: () => import("features/source-connections/routes/schedule-sync"),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },

        // ------------------------------------
        // Superuser routes
        // ------------------------------------
        {
          path: "/superuser",
          lazy: () => import("layouts/superuser-layout"),
          children: [
            {
              errorElement: <ErrorPage />,

              children: [
                // organization routes
                {
                  path: "organizations",
                  children: [
                    {
                      index: true,
                      lazy: () => import("features/superuser/routes/organizations"),
                    },
                  ],
                },

                // user routes
                {
                  path: "users",
                  children: [
                    {
                      index: true,
                      lazy: () => import("features/superuser/routes/users"),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  return routes;
}
