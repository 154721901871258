import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosError } from "axios";
import clsx from "clsx";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { type ReactElement, useState } from "react";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import { z } from "zod";

import { useRequestPasswordReset } from "features/auth";
import toast from "lib/react-hot-toast";

const validationSchema = z.object({
  email: z.string().email(),
});

export type RequestPasswordResetData = z.infer<typeof validationSchema>;

const defaultValues = {
  email: "",
};

function setFormErrors(error: AxiosError, setError: UseFormSetError<RequestPasswordResetData>) {
  // @ts-expect-error - We have not properly defined the error response data for every case
  const errors = error.response?.data?.errors;
  if (!errors) {
    toast.error("Something went wrong. Please try again shortly or contact QuickBI support.");
  }

  for (const error of errors) {
    const sourcePointer = error.source?.pointer;
    if (sourcePointer?.includes("email")) {
      setError("email", { type: "server", message: error.detail }, { shouldFocus: true });
    } else {
      setError("root", { type: "server", message: error.detail });
    }
  }
}

export function RequestPasswordResetForm(): ReactElement {
  const [requested, setRequested] = useState(false);
  const requestReset = useRequestPasswordReset();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    setError,
  } = useForm<RequestPasswordResetData>({
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  function onSubmit({ email }: RequestPasswordResetData): void {
    requestReset.mutate(email, {
      onSuccess: () => {
        setRequested(true);
      },
      onError: (error) => {
        setFormErrors(error as AxiosError, setError);
      },
    });
  }

  function getFormErrorMessage(
    name: keyof RequestPasswordResetData | "root",
  ): ReactElement | undefined {
    if (!errors[name]) {
      return undefined;
    }
    if (name === "root") {
      return <Message className="mb-2" severity="error" content={errors.root?.message} />;
    }
    return <small className="p-error block">{errors[name]?.message}</small>;
  }

  if (requested) {
    return (
      <Message
        severity="success"
        content={
          <p>
            Instructions to change your password have been sent to {getValues("email")} if a QuickBI
            account with that email address exists.
          </p>
        }
      />
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
      <div className="field mb-4">
        <label htmlFor="email" className={clsx({ "p-error": errors.email })}>
          Email
        </label>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }): ReactElement => (
            <InputText
              id={field.name}
              autoFocus
              {...field}
              className={clsx("my-2 placeholder-gray-300", { "p-invalid": fieldState.invalid })}
            />
          )}
        />
        {getFormErrorMessage("email")}
      </div>
      {getFormErrorMessage("root")}

      <Button
        type="submit"
        size="small"
        label={requestReset.isPending ? "Submitting..." : "Submit"}
        className="p-button mt-2"
        disabled={requestReset.isPending}
      />
    </form>
  );
}
